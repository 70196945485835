/**
 * 
 * @desc 个性化开关utils逻辑前端用
 * 
 * */ 
import { getCookie } from '@shein/common-function'

export const TRACK_KEY = 'uberctx-personal-switch'
const COOKIE_KEY = 'uswitch'
const cookieMapArray = [
  ['s', 'search'], 
  ['r', 'recommend'], 
  ['u', 'userGrowth']
]

export const getSwitchByCookie = () => {
  const cookieValue = getCookie(COOKIE_KEY)
  let result
  if (cookieValue) {
    result = decodeURIComponent(cookieValue).split('&').reduce((acc, cur) => {
      const [key, value] = cur.split(':')
      // 只会返回做了个性化设置改变的配置（默认是开启，值 1，关闭时候 0）
      if (Number(value) === 0) {
        // eslint-disable-next-line no-unused-vars
        const hasDefine = cookieMapArray.find(([k, _]) => k === key)
        if (hasDefine) acc[hasDefine[1]] = Number(value)
      }
      return acc
    }, {})
  }
  return result
}


export const setPersonalizedHeader = (config) => {
  const commonHeaders = {
  }
  const result = getSwitchByCookie()
  if (result && Object.keys(result).length) { commonHeaders[TRACK_KEY] = encodeURIComponent(JSON.stringify(result)) }
  config.headers = Object.assign(commonHeaders, config.headers)
  // END
  return config
}
